<template>
  <div>
    <!-- Hero Section with Overlay Navbar and Carousel -->
    <section class="hero-section position-relative">
      <!-- Beautified Overlay Navbar -->
      <nav :class="['hero-navbar', { 'navbar-scrolled': scrolled }]">
        <a class="navbar-brand" href="#">
          <img src="../assets/logo3.png" alt="Logo" />
        </a>
        <button class="navbar-toggler d-lg-none" type="button" @click="toggleMenu">
          <span class="navbar-toggler-icon">☰</span>
        </button>
        <div :class="['nav-links', { 'show-menu': menuOpen }]">
          <router-link class="nav-ink" to="/main/services" @click="setActive('cropping')">Services</router-link>
          <router-link class="nav-ink" to="/main/dashboard" @click="setActive('Dashboards')">Dashboards</router-link>
          <router-link class="nav-ink" to="/main/storyset" @click="setActive('Storyset')">StorySet</router-link>
          <router-link class="nav-ink" to="/main/datasets" @click="setActive('Dataset')">Dataset</router-link>
          <router-link class="nav-ink" to="/main/resources" @click="setActive('Resources')">Resources</router-link>
          <router-link class="nav-ink" to="/main/faq" @click="setActive('faq')">Support</router-link>
        </div>
      </nav>

      <!-- Carousel -->
      <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" data-bs-interval="6000">
        <!-- Carousel Indicators -->
        <div class="carousel-indicators">
          <button v-for="(slide, index) in slides" :key="index" type="button"
            data-bs-target="#carouselExampleIndicators" :data-bs-slide-to="index" :class="{ active: index === 0 }"
            :aria-current="index === 0 ? 'true' : null" :aria-label="`Slide ${index + 1}`"></button>
        </div>

        <!-- Carousel Slides -->
        <div class="carousel-inner">
          <div v-for="(slide, index) in slides" :key="index" :class="['carousel-item', { active: index === 0 }]">
            <img :src="slide.image" class="d-block carousel-image w-100" alt="Slide Image"
              style="height: 100vh; object-fit: cover;">
            <!-- Centered Caption -->
            <div class="carousel-caption d-flex flex-column justify-content-center align-items-center text-center">
              <div>
                <h1 class="poppins-extrabold" :class="['animate__animated animate__slow', slide.animationClass]">{{
                  slide.text }}
                </h1>
                <p class="fw-medium" :class="['animate__animated animate__slower', slide.animationClass]" v-if="slide.description">{{
                  slide.description }}</p>
                <div class="d-flex justify-content-center mt-3">
                  
                  <router-link to="/main/services" class="button animate__animated text-decoration-none animate__slower animate__delay-1s animate__bounceInUp" style="color: white; ">
                    <svg class="svgIcon" viewBox="0 0 512 512" height="1em" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm50.7-186.9L162.4 380.6c-19.4 7.5-38.5-11.6-31-31l55.5-144.3c3.3-8.5 9.9-15.1 18.4-18.4l144.3-55.5c19.4-7.5 38.5 11.6 31 31L325.1 306.7c-3.2 8.5-9.9 15.1-18.4 18.4zM288 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
                        fill="white"></path>
                    </svg>
                    <div class="poppins-medium text-center w-100">
                      Explore More
                    </div>
                                </router-link>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- Controls -->
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </section>

    <LandingOffcanvas class="animate__animated animate__fadeInUpBig animate__delay-2s animate__slow" />
    <!-- Content Section -->
    <section class="content-section section-animate">
      <div class="container">
        <LandingServices />
      </div>
    </section>

    <section class="partner-section section-animate">
      <div class="container col-md-12">
        <article class="blog-post text-start">
          <h2>Our Partners</h2>
          <vueper-slides class="no-shadow" :visible-slides="8" :gap="7" :duration="3000" :bullets="false"
            :autoplay="true" :arrows="false" :slide-ratio="1 / 4" :dragging-distance="70">
            <vueper-slide v-for="(slide, i) in slides2" :key="i" :image="slide" class="partner-slide" />
          </vueper-slides>
        </article>
      </div>
    </section>
    

    <section class="faq-section section-animate mb-5">
      <div class="container">
        <h1 class="mt-3 text-start">- Frequently Asked Questions</h1>
        <div class="accordion" id="accordionExample">
          <div class="accordion-item bg-transparent rounded-none border-top border-0 border-light" v-for="(item, i) in faqs" :key="i">
            <h2 class="accordion-header">
              <button class="accordion-button  bg-transparent text-light collapsed" type="button"
                data-bs-toggle="collapse" :data-bs-target="'#collapse' + i" aria-expanded="false"
                :aria-controls="'collapse' + i">
                {{ i + 1 }}. {{ item.question }}
              </button>
            </h2>
            <div :id="'collapse' + i" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body ms-4 text-start">
                - {{ item.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <br>   <br>
    <footer class="d-flex flex-wrap justify-content-between align-items-center pb-2 my-5 mt-5 border-top "  style="" >
        <div class="col-md-4  d-flex mx-auto align-items-center">
            <!-- <a href="/" class="mb-3 me-2 mb-md-0 text-light text-decoration-none lh-1">
                <svg class="bi" width="30" height="24"><use xlink:href="#bootstrap"/></svg>
            </a> -->
            <span class="text-light ">&copy; {{new Date(Date.now()).getFullYear()}} Ag-Datahub</span>
        </div>

        <ul class="nav col-md-6  mx-auto  me-md-5  justify-content-end list-unstyled  d-flex">
            <li class="ms-3"><router-link to="/main/services" class="text-light">Services</router-link></li>
            <li class="ms-3"><router-link to="/main/dashboard" class="text-light">Dashboards</router-link></li>
            <li class="ms-3"><router-link to="/main/storyset" class="text-light">StorySet</router-link></li>
            <li class="ms-3"><router-link to="/main/datasets" class="text-light">Dataset</router-link></li>
            <li class="ms-3"><router-link to="/main/resources" class="text-light">Resources</router-link></li>
            <li class="ms-3"><router-link to="/main/faq" class="text-light">Support</router-link></li>
        </ul>
    </footer>
  </div>
</template>

<script>
import 'animate.css';
import LandingServices from '../components/landingServices.vue';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import LandingOffcanvas from '../components/landingOffcanvas.vue';

export default {
  name: "LandingPage",
  components: {
    LandingServices,
    VueperSlides,
    VueperSlide,
    LandingOffcanvas
  },
  data() {
    return {
      scrolled: false,
      scrolled: false,
      menuOpen: false,
      slides2: [
        require("../assets/stk/Layer 2.png"),
        require("../assets/stk/Layer 3.png"),
        require("../assets/stk/Layer 4.png"),
        require("../assets/stk/Layer 5.png"),
        require("../assets/stk/Layer 18.png"),
        require("../assets/stk/Layer 6.png"),
        require("../assets/stk/Layer 7.png"),
        require("../assets/stk/Layer 8.png"),
        require("../assets/stk/Layer 9.png"),
        require("../assets/stk/Layer 10.png"),
        require("../assets/stk/Layer 11.png"),
        require("../assets/stk/Layer 12.png"),
        require("../assets/stk/Layer 13.png"),
        require("../assets/stk/Layer 14.png"),
        require("../assets/stk/Layer 15.png"),
        require("../assets/stk/Layer 16.png"),
        require("../assets/stk/Layer 17.png"),
      ],
      slides: [
  {
    image: require("../assets/home/home1.jpg"),
    text: "Powering Agriculture With Data",
    animationClass: "animate__bounceInDown",
    buttonText: "Learn More",
    description: "Access tools and insights to support modern farming. Make smarter decisions using reliable agricultural data."
  },
  {
    image: require("../assets/home/home2.jpg"),
    text: "Explore Datasets. Discover Insights.",
    animationClass: "animate__bounceInDown",
    buttonText: "Get Started",
    description: "Browse curated datasets from trusted sources. Visualize trends and drive your research forward."
  },
  {
    image: require("../assets/home/home3.jpg"),
    text: "Transform Farming With Innovation",
    animationClass: "animate__bounceInDown",
    buttonText: "Join Now",
    description: "Connect with stakeholders and share knowledge. Build sustainable, data-driven agricultural solutions."
  }
],

      faqs: [
        {
          question: 'How do I add data to datasets?',
          answer: 'you\'ll need to create an account and to be able to add, however your uploaded dataset will need to be approved by the Aghub team'
        },
        {
          question: 'Why can\'t I download dataset?',
          answer: 'if you cant download a dataset it probably because the owner of such data has declared it private, if not so kindly contact us or leave a message and we will revert'
        },
        {
          question: 'What is the purpose of the cropping calendar?',
          answer: 'The cropping calender provides the farmer a sequential order of activities to be taken on the farm this is calculated based on the onset of rain. this details when to plant, harvest, weed and so on'
        },
        {
          question: 'what is the extension portal',
          answer: 'the extension portal is managed by extension managers, who serve farmers(extensions) with educative materials, this portal allows extension officers to send messages to farmers via email and sms portals'
        },
        {
          question: 'How do I signup as an extension manager or officer',
          answer: 'To registered in or system as an officer kindly leave as a message with your contact and we will get back to you. Terms and Conditions Apply'
        }
      ],
    };
  },
  mounted() {
  window.addEventListener("scroll", this.handleScroll);

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate__animated", "animate__fadeInUp");
          entry.target.classList.remove("section-animate-hidden"); // optional, see CSS
        }
      });
    },
    {
      threshold: 0.2,
    }
  );

  // Observe all sections with `section-animate`
  const sections = this.$el.querySelectorAll(".section-animate");
  sections.forEach((section) => {
    section.classList.add("section-animate-hidden"); // add opacity 0 initially
    observer.observe(section);
  });
},

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 40;
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    setActive(section) {
      this.active = section;
      this.menuOpen = false;
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.section-animate-hidden {
  opacity: 0;
}
.section-animate.animate__animated {
  opacity: 1;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b6fe00;
  outline: none;
  box-shadow: 0 0 0 .25rem rgba(13, 109, 253, 0);
}
.accordion-item{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.hero-section {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.hero-navbar {
  position: absolute;
  top: 40px;
  left: 10%;
  right: 10%;
  z-index: 100;
  padding: 20px 30px;
  background: rgb(0, 119, 18);
  backdrop-filter: blur(10px);
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.navbar-scrolled {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgb(209, 178, 0);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
}

.navbar-scrolled .nav-ink {
  color: #ffffff !important;
}

.navbar-scrolled .nav-ink:hover {
  color: white !important;
}

.hero-navbar .navbar-brand img {
  width:120px;
  object-fit: contain;
}

.navbar-toggler {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  padding: 5px 10px;
}
.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow:none;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links .nav-ink {
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none;
  color: #f0f0f0;
  background-color: transparent;
  text-wrap: inherit;
  padding: 4px 14px;
  border-radius: 6px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

@media (max-width: 991px) {
  .nav-links {
    gap: 0px;
    display: none;
    flex-direction: column;
    width: 100%;
    background: rgb(0, 119, 18);
    position: absolute;
    top: 100%;
    left: 0;
    padding: 10px;
    z-index: 99;
  }
  .nav-links.show-menu {
    display: flex;
  }
  .nav-links .nav-ink {
    font-size: 0.9rem;
    padding: 8px 12px;
    margin: 4px 0;
  }
}

@media (max-width: 576px) {
  .nav-links .nav-ink {
    font-size: 0.8rem;
    padding: 6px 10px;
    margin: 3px 0;
  }
}
.nav-links .nav-ink:hover {
  transition: background-color 0.5s cubic-bezier(0.47, 0, 0.745, 0.715); 
  background-color: rgb(163, 0, 0);
  color: white;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
}

.carousel-caption h2 {
  font-size: 3rem;
  font-weight: bold;
}

.carousel-caption p {
  font-size: 1.2rem;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .carousel-caption h2 {
    font-size: 2rem;
  }

  .carousel-caption p {
    font-size: 1rem;
  }

  .hero-navbar {
    flex-direction: row;
    padding: 15px;
    left: 5%;
    right: 5%;
  }
}

.content-section {
  padding: 60px 0;
  
  background-color: rgba(91, 0, 0, 0.904); /* Fallback for browsers that don't support gradients */
  background-image: linear-gradient(rgba(91, 0, 0, 0.904), rgba(91, 0, 0, 0.904)), url('../assets/home/maize.jpg');
  background-size: cover;
}

.vueperslide {
  background-size: contain;
  position: relative;
  background-repeat: no-repeat;
  height: 100px !important;
  filter: grayscale(100%);
  transition: filter 0.3s ease, transform 0.3s ease;
  max-height: 1in;
}
.vueperslides {
  max-height: 1in;
}
.vueperslide:hover {
  filter: grayscale(0%);
  transform: scale(1.05);
}

.carousel-image {
  filter: grayscale(100%) brightness(0.5);
  transition: filter 0.5s ease, transform 0.5s ease;
}

.carousel-image:hover {
  filter: grayscale(0%) brightness(0.5);
  transform: scale(1.05);
}

.partner-section {
  padding: 60px 0;
  background-color: #dddddd;
}
.faq-section {
  padding: 60px 0;
  background-color: rgba(0, 91, 17, 0.904); /* Fallback for browsers that don't support gradients */
  background-image: linear-gradient(rgba(0, 91, 17, 0.904), rgba(0, 91, 17, 0.904)), url('../assets/home/maize.jpg');
  background-size: contain;
  color: white;
}
</style>

<style scoped>
/* From Uiverse.io by vinodjangid07 */
.button {
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-inline-end: 10px;
  justify-content: space-between;
  gap: 10px;
  background-color: rgb(126, 0, 0);
  border-radius: 30px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  border: none;
  position: relative;
  cursor: pointer;
  transition-duration: .2s;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.116);
  padding-left: 8px;
  font-style: normal;
  transition-duration: .5s;
}

.svgIcon {
  height: 35px;
  transition-duration: 1.5s;
  color: rgb(255, 255, 255);
}

.bell path {
  fill: rgb(19, 19, 19);
}

.button:hover {
  background-color: rgb(163, 152, 0);
  transition-duration: .5s;
  font-style: italic;

}

.button:active {
  transform: scale(0.97);
  transition-duration: .2s;
}

.button:hover .svgIcon {
  transform: rotate(250deg);
  transition-duration: 1.5s;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}
</style>


<style scoped>
    footer{
        background-color:rgb(209, 178, 0); 
        margin-bottom: 0% !important;
        margin-top:19.3rem !important;
        height: 100px;
        position:absolute;
        bottom: 0;
        width:100%;
        z-index: 1000;
    }
    li .text-light{
        text-decoration: none;
    }
    li{
        transition:  .5s;
        border-radius: 4px;
        padding-inline: 15px;
        background-color: transparent; 
        transition: background-color 0.5s cubic-bezier(0.47, 0, 0.745, 0.715); 
    }
    li:hover{
        background-color: #750000;
        border-radius: 4px;
        padding-inline: 15px;
    }
</style>