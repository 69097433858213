<template>
    <div class="offcanvas offcanvas-bottom show px-5" style="visibility: visible;" data-bs-backdrop="false"
        data-bs-scroll="true" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
        <div class="offcanvas-header py-0 px-3 rounded-2">
            <i type="button" data-bs-dismiss="offcanvas" style="color: red;" class="bi bi-x fs-2 "></i>

            <div class="weatherMain">
                <div class="card bg-transparent  border-0 m-auto" v-if="weatherdatamain && weatherdatamain.length">
                    <div class="card-body p-0">
                        <div class=" align-self-center text-center">
                            <i class="bi bi-calendar-check-fill fs-5 mx-2 text-success"></i>
                            <h5 class="d-inline fs-6 text-capitalize">{{ formatDay(weatherdatamain[0].dt) }}</h5>
                            <i class="bi bi-chevron-right mx-2"></i>
                            <p class="d-inline text-capitalize" style="font-size:20px;">{{ weatherdatamain[1].split(' ')[0] }}</p>
                            <i class="bi bi-chevron-right mx-2"></i>
                            <p class="d-inline text-capitalize" style="font-size: 25px;">{{ weatherdatamain[0].main.temp
                                }}°</p>
                            <img :src="'https://openweathermap.org/img/wn/' + weatherdatamain[0].weather[0].icon + '@2x.png'"
                                width="40">
                            <p class="text-capitalize d-inline">{{ weatherdatamain[0].weather[0].description }}</p>
                        </div>
                        <!-- <div class="row justify-content-md-center">
      <div class="col text-center" v-for="data in reusltdata[0]" :key="data.dt">
        <p class="text-capitalize pb-0 text-success fw-bolder" style="font-size:13px; margin-bottom: 0px !important;">
          {{ data.main.temp }}° {{ data.weather[0].description }}
        </p>
        <p class="text-capitalize pb-0" style="font-size:10px">{{ timeOfDay(data.dt) }}</p>
      </div>
    </div> -->
                    </div>
                </div>

            </div>
        </div>
        <div class="offcanvas-body overflow-hidden small">
            <footer class="row pb-3 g-3 align-items-stretch flex-nowrap overflow-auto">
                    <div class="col-lg-4 col-sm-8 mt-2 p-4 overflow-hidden towns d-flex flex-column">
                        <div class="input-group input-group-sm ">
                            <input class="form-control" type="text" v-model="searchdata" @keyup="search(searchdata)"
                                placeholder="Search">
                            <button type="button" class="btn text-light m-0" style="background-color:#c2213d"
                                @click="search()">All locations</button>
                        </div>
                        <div class="list-group mt-2 text-sm-center text-lg-start flex-grow-1 overflow-auto">
                            <a href="#"
                                @click="getLocationData([locationData.latitude, locationData.longitude, locationData.location])"
                                style="font-size:11px"
                                class="text-capitalize list-group-item ps-3 list-group-item-action"
                                v-for="locationData in searchdatares" :key="locationData.id">
                                {{ locationData.location }}
                            </a>
                        </div>
                    </div>

                    <div class="col px-3 py-3 m-2 d-flex flex-column justify-content-center align-items-center text-center" v-if="!reusltdata">
                        <span class="loader"></span>
                        <p class="mt-1">You're hard to find, please wait or use search alt.</p>
                    </div>
                    <template v-else>
                    <div class="col px-3 py-3 m-2 d-flex flex-column justify-content-center align-items-center text-center weather-col"
                        v-for="(daydata, id) in reusltdata" :key="id"
                        @click="$emit('populatemain', [daydata, addressVal])">
                        <span class="badge rounded-pill py-2 px-5 fw-light mb-0" :class="badgecolors()">
                            {{ getday(daydata[0].dt) }}
                        </span>
                        <ul class="nav flex-column">
                            <li class="nav-item my-0">
                                <h3>{{ daydata[0].main.temp }}°</h3>
                            </li>
                            <li class="nav-item mb-0">
                                <img :src="'https://openweathermap.org/img/wn/' + daydata[0].weather[0].icon + '@2x.png'"
                                    width="55">
                                <h6>{{ daydata[0].weather[0].description }}</h6>
                            </li>
                        </ul>
                    </div>
                </template>
            </footer>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Offcanvas } from 'bootstrap';
export default {
    name: 'AutoOffcanvas',
    data() {
        return {
            weatherdatamain: '',
            addressVal: 'default',
            locations: null,
            reusltdata: null,
            searchdata: null,
            searchdatares: null,
            weekdata: null,
            iconclass: null,
            address: '',
            maindatatwo: []
        };
    },
    mounted() {
        this.$nextTick(() => {
            const el = document.getElementById('offcanvasBottom');
            if (el) {
                const offcanvas = new Offcanvas(el);
                offcanvas.show();
            }
        });
    },
    computed: {
        getdata: {
            get() {
                return this.reusltdata || this.weekdata;
            },
            set(newValue) {
                this.reusltdata = newValue;
            }
        }
    },
    created() {
        this.search();
        navigator.geolocation.getCurrentPosition(this.success, this.error);
    },
    methods: {
        formatDay(timestamp) {
            return new Date(timestamp * 1000).toDateString();
        },
        timeOfDay(dt) {
            const hrs = new Date(dt * 1000).getHours();
            if (hrs < 12) return 'Morning';
            else if (hrs >= 12 && hrs < 17) return 'Afternoon';
            else return 'Evening';
        },
        success(pos) {
            const crd = pos.coords;
            const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${crd.latitude}&lon=${crd.longitude}`;
            axios.get(url)
                .then(response => {
                    const onMountLocation = [crd.latitude, crd.longitude, response.data.display_name];
                    this.getLocationData(onMountLocation);
                })
                .catch(console.log);
        },
        error(err) {
            console.warn(`ERROR(${err.code}): ${err.message}`);
        },
        badgecolors() {
            const colors = ["primary", "success", "warning", "danger", "dark", "info"];
            const name = 'bg-' + colors[Math.floor(Math.random() * colors.length)];
            return name;
        },
        getday(timestamp) {
            const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            const date = new Date(timestamp * 1000);
            return days[date.getDay()];
        },
        search(location = null) {
            let url = `https://aghub.miphost.com/api/weather/locations`;
            if (location) url += `/${location}`;
            axios.get(url)
                .then(response => this.searchdatares = response.data)
                .catch(console.log);
        },
        getLocationData(location) {
            const sixDayArr = [];
            const daysArr = [];
            axios.get(`https://api.openweathermap.org/data/2.5/forecast?lat=${location[0]}&lon=${location[1]}&appid=84e1433cb3c4fe512da5cc71b670f8fa&units=metric`)
                .then(response => {
                    response.data.list.forEach(el => {
                        const newDate = new Date(el.dt * 1000);
                        el['dtn'] = newDate.toDateString();
                        if (!daysArr.includes(el.dtn)) daysArr.push(el.dtn);
                    });
                    daysArr.forEach(day => {
                        const eachArr = [];
                        response.data.list.forEach(val => {
                            if (val.dtn === day) {
                                const hrs = new Date(val.dt * 1000).getHours();
                                if ((hrs < 12 && eachArr.length === 0) ||
                                    (hrs >= 12 && hrs < 17 && eachArr.length === 1) ||
                                    (hrs >= 17 && eachArr.length === 2)) {
                                    eachArr.push(val);
                                }
                            }
                        });
                        sixDayArr.push(eachArr);
                        this.addressVal = location[2];
                    });

                      sixDayArr.splice(0, 1);
                    this.reusltdata = sixDayArr;

                    return axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${location[0]}&lon=${location[1]}&appid=84e1433cb3c4fe512da5cc71b670f8fa&units=metric`);
                })
                .then(response => {
                    this.weatherdatamain = [response.data, location[2]]
                })
                .catch(console.log);
        }
    }
};
</script>





<style scoped>
.btn {
    margin-bottom: 20px;
}
.card{
    border: none;
}
.weatherMain {
    background-color: #00000060;
    background-size: cover;
    backdrop-filter: blur(10px);
    padding-inline: 10px;
    border-radius: 10px;
}

.towns {
    background: #0000005c;
    box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
    border-radius: 10px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.weather-col {
  background: #0000005c;
  border-radius: 10px;
  border: none;
  transition: transform 0.4s linear;
  height: 200px; /* Reduced height */
  width: 120px;   /* Reduced width */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* Makes scrollable if content exceeds */
  padding: 0.5rem;
}


.weather-col:hover {
    transform: scale(1.05);
    opacity: 0.85;
    margin-top: 1%;
}

.offcanvas {
    background-color: #f8f9fa2b;
    background-size: cover;
    backdrop-filter: blur(10px);
    color: #fff;
}

footer {
    /* background-size: cover; */
    /* box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2); */
    border-radius: 5px;
    /* backdrop-filter: blur(14px);
  background-color: rgba(1, 0, 0, 0); */
    text-align: center;
    color: aliceblue;
}



.badge {
    letter-spacing: 1px;
}

input {
    background-color: #e9ecef00;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #f0f8ff75;
}

.form-control:focus {
    color: #ffffff;
    background-color: #ffffff12;
    border-color: #9595958c;
    outline: 0;
    border-radius: 5px 5px 0 0;
    box-shadow: none;
}

.form-control {
    height: 30px;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #fff !important;
}

::-webkit-scrollbar-thumb {
    background: #c2213d;
}

::-webkit-scrollbar-thumb:hover {
    background: #7a091c;
}

.list-group {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.list-group-item {
    color: #f8f9fa;
    background: none !important;
    border: none !important;
}

.list-group-item-action:hover {
    color: #f47d91 !important;
    border-radius: 51px;
    background-color: #6c757d29 !important;
}

@media only screen and (max-width: 1000px) {
    .fixed-bottom {
        position: relative !important;
    }
}

@media (max-width: 768px) {
    .offcanvas {
        height: 100vh !important;
        overflow-y: auto;
    }

    footer.row {
        flex-direction: column !important;
        align-items: stretch !important;
    }

    .weather-col,
    .towns {
        width: 100% !important;
        margin: 10px 0;
    }

    .offcanvas-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .weatherMain {
        width: 100%;
        margin-top: 1rem;
    }

    .weatherMain h5,
  .weatherMain p {
    font-size: 12px !important;
  }

  .weatherMain img {
    width: 30px !important;
  }

  .weatherMain .bi {
    font-size: 14px !important;
  }

  footer.row {
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 1rem;
  }

  .weather-col {
    flex: 0 0 auto;
    height: 140px;
    width: 110px;
    font-size: 10px;
    border-radius: 10px;
  }

  .weather-col h3 {
    font-size: 16px;
  }

  .weather-col h6 {
    font-size: 10px;
  }

  .weather-col img {
    width: 35px;
  }
}



.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #FFF #FFF transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #FF3D00 #FF3D00;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
}

.loader::before {
    width: 32px;
    height: 32px;
    border-color: #FFF #FFF transparent transparent;
    animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotationBack {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}
</style>