<template>
    <section class="">
        <div class="container">
            <div class="row align-items-start mb-5">
                <div class="col-lg-6 mb-4 mb-lg-0 text-white text-start">
                    <h2 class="fw-bold text-white mb-3">Work with tools you already use</h2>
                    <p class="text-white mb-4">


                        Seamless access to agricultural data can transform food systems, eliminate hunger and poverty, and support climate-smart practices. The Ghana Agricultural Data Hub (Ag-Data Hub) is being developed as a secure, reliable platform for data sharing and informed decision-making to drive policy and sustainable agriculture.                                       </p>
                    <ul class="list-unstyled border-top pt-4">
                        <li
                            v-for="(item, index) in items"
                            :key="index"
                            class="d-flex align-items-center mb-3 li-hover"
                            :class="{ 'text-warning': hoveredIndex === index }"
                            @mouseover="hoveredIndex = index"
                            @mouseleave="hoveredIndex = null"
                            @click="$router.push(item.route)"
                            style="cursor: pointer"
                        >
                            <i
                                class="me-2"
                                :class="{ 'text-warning bi bi-check-circle-fill link_icon': hoveredIndex === index, 'bi bi-check-circle text-white': hoveredIndex !== index }"
                            ></i>
                            <span class="ps-2 text-white" :class="{ 'text-decoration-underline link_icon': hoveredIndex === index }">
                                {{ item.title }}
                            </span>
                        </li>
                    </ul>
                    <p class="text-white">
                        Deliver great service experiences fast - without the complexity of traditional ITSM solutions.
                    </p>
                </div>
                <div class="col-lg-6">
                    <div class="row" data-masonry='{"percentPosition": true }'>
                        <div
                            v-for="(item, index) in items"
                            :key="index"
                            class="col-6 mb-4"
                            @mouseover="hoveredIndex = index"
                            @mouseleave="hoveredIndex = null"
                        >
                            <Tooltip :text="item.description" :title="item.title" placement="bottom">
                                <router-link
                                    :to="item.route"
                                    class="card h-100 shadow-sm border-0 image-card text-decoration-none"
                                   
                                >
                                    <img
                                        :src="item.image"
                                        class="card-img-top img-fluid shadow-md"
                                        :alt="item.title"
                                    />
                                </router-link>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Masonry from "masonry-layout";
import Tooltip from "./tooltip.vue";

export default {
    name: "FeatureGridSection",
    components: {
        Tooltip,
    },
    data() {
        return {
            hoveredIndex: null,
            items: [
                {
                    image: require("../assets/services.png"),
                    title: "Services",
                    description: "Use our expert-built services tailored to your interests. These apps are powered by refined data found right here.",
                    route: "/main/services",
                },
                {
                    image: require("../assets/storyset.png"),
                    title: "Storyset",
                    description: "This summarizes a decade of analysis from the Africa RISING Dataverse, highlighting key findings and other crops relevant to our goals.",
                    route: "/main/storyset",
                },
                {
                    image: require("../assets/dashboard.png"),
                    title: "Dashboard",
                    description: "Select a dataset, choose the data fields you care about, and we’ll generate charts and graphs just for you. Not satisfied? Customize them your way and tell your own data story.",
                    route: "/main/dashboard",
                },
                {
                    image: require("../assets/datasets.png"),
                    title: "Datasets",
                    description: "Find the data you need for your projects on AgData-Hub. Explore, upload, and share datasets with the community—just sign up to get started.",
                    route: "/main/datasets",
                },
                {
                    image: require("../assets/croppingcalendar.png"),
                    title: "Cropping Calendar",
                    description: "The Cropping Calendar provides timely, data-driven guidance for planting and harvesting to boost yields.",
                    route: "/main/croppingcalendar",
                },
                {
                    image: require("../assets/wearther.png"),
                    title: "Weather",
                    description: "Weather Forecast Interface  offers real-time and future weather updates to help you plan and make informed farming decisions.",
                    route: "/weather",
                },
                {
                    image: require("../assets/extportal.png"),
                    title: "Extentions Portal",
                    description: "Our Extention Officers help manage Farmers Digitaly, The Ext. Portal helps them add, groups, send messges and also share information with farmers",
                    route: "/main/services",
                },
            ],
        };
    },
   mounted() {
    const container = this.$el.querySelector(".row[data-masonry]");
    if (container) {
        const images = container.querySelectorAll("img");
        let imagesLoaded = 0;

        images.forEach((img) => {
            if (img.complete) {
                imagesLoaded++;
                if (imagesLoaded === images.length) {
                    this.initMasonry(container);
                }
            } else {
                img.onload = () => {
                    imagesLoaded++;
                    if (imagesLoaded === images.length) {
                        this.initMasonry(container);
                    }
                };
            }
        });
    }
},
methods: {
    initMasonry(container) {
        new Masonry(container, {
            itemSelector: ".col-6",
            percentPosition: true,
        });
    }
}
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1,h2,h3,h4,h5,h6,p,span,a,li, span, div, button, input, textarea, select {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

img {
    filter: brightness(0.8) saturate(1.2);
}
img:hover {
    filter: brightness(0.9);
}
.image-card {
    transition: transform 0.2s ease-in-out;
}
.image-card:hover {
    transform: scale(1.02);
    z-index: 2;
}
.text-decoration-underline {
    text-decoration: underline;
}
.link_icon{
    transform: scale(1.1);
    font-weight: 600;
    transition: transform 0.3s ease-in-out;
}
.li-hover:hover {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
}

</style>