<template>
    <div class="custom-tooltip-wrapper" @mouseenter="show = true" @mouseleave="show = false">
      <slot />
      <div v-if="show" class="custom-tooltip" :class="placement">
        <h6 class="fw-bold"> {{ title }}</h6> 
        {{ text }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CustomTooltip',
    props: {
        title: {
        type: String,
        required: true
      },
      text: {
        type: String,
        required: true
      },
      placement: {
        type: String,
        default: 'bottom' // top, right, left also supported
      }
    },
    data() {
      return {
        show: false
      };
    }
  };
  </script>
  
  <style scoped>
  .custom-tooltip-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .custom-tooltip {
    position: absolute;
    background-color: rgb(108, 78, 0);
    width: 300px;
    color: #ffffff;
    padding: 0.75rem;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    font-size: 0.875rem;
    z-index: 2000;
    transition: opacity 0.2s ease;
  }
  
  /* Positioning */
  .custom-tooltip.bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5px;
  }
  .custom-tooltip.top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;
  }
  .custom-tooltip.left {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 10px;
  }
  .custom-tooltip.right {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10px;
  }
  </style>
  